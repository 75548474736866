import { Link, navigate, PageProps, withPrefix } from 'gatsby';
import React, { useEffect, useState } from 'react';
import LoadingContainer from 'src/components/LoadingContainer';
import useDateFormat from 'src/hooks/useDateFormat';
import useDowngradeInventory from 'src/hooks/useDowngradeInventory';

import { trackPageLoad } from 'src/services/tracker';
import { useAuthContext, useRequireAuthenticated } from 'src/components/AuthProvider';
import { Translate } from 'src/components/Translate';
import { Banner } from 'src/components/molecule';
import { PaymentMethod, TransactionsList } from 'src/components/organism';
import Breadcrumbs from 'src/components/Breadcrumbs';
import AutoRenewButton from 'src/components/atom/AutoRenewButton';
import usePaymentFailed from 'src/hooks/usePaymentFailed';
import useDefaultPaymentMethod from 'src/api/useDefaultPaymentMethod';
import useMembership from 'src/api/useMembership';
import useOrders from 'src/api/useOrders';
import useRedirect from 'src/hooks/useRedirect';
import { SimpleFooter } from 'src/features/footer';

const checkoutUrlVariables = { url: withPrefix('/checkout/') };

function PaymentBill({ location }: PageProps) {
  const { state = {} } = location;
  const { isAuthenticated } = useAuthContext();
  const { defaultPaymentMethod, defaultPaymentMethodLoading } = useDefaultPaymentMethod();
  const { data: membership, ...membershipQuery } = useMembership();
  const [card, setCard] = useState(null);
  const [banner, setBanner] = useState(null);
  const format = useDateFormat();

  const { orderData: orders, orderMeta, orderFetchMore, orderRefetch, orderStartPolling } = useOrders(
    { fetchPolicy: 'network-only' },
    { 'filter[contain]': 'transaction' }
  );
  const { paymentMethodError, paymentFailed } = usePaymentFailed(defaultPaymentMethod);

  useRequireAuthenticated();
  useRedirect('/my-account/edit-card/');

  useEffect(() => {
    if (isAuthenticated) {
      membershipQuery.refetch();
      orderRefetch();
      orderStartPolling(1000 * 60 * 5); // Poll interval - every 5 minutes
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (membership?.data) {
      trackPageLoad({
        pathname: location.pathname,
        membership_level: membership.data.name,
        hdc_status: membership.data.subscription?.status,
        price: membership.data.price / 100,
        renewal_date: format(membership.expireAt),
      });
    }
  }, [membership?.data]);

  // Load default payment method
  useEffect(() => {
    if (!defaultPaymentMethod?.card?.last4) return;
    setCard({ ...defaultPaymentMethod.card, token: defaultPaymentMethod.id });
  }, [defaultPaymentMethod]);

  const message = state?.['msg'];
  useEffect(() => {
    if (message) {
      membershipQuery.stopPolling();
      setBanner({ display: true, message, color: 'success' });
    }
  }, [message]);

  const i18n = {
    title: 'sections.billing.title',
    breadcrumb: 'breadcrumbs.myAccount.text',
    payment: {
      balance: 'myAccount.billing.balance.title',
      title: 'myAccount.billing.payment.title',
      cardTitle: 'myAccount.billing.card.title',
      cardDescription: 'myAccount.billing.card.description',
      membershipTitle: 'myAccount.billing.membership.title',
      membershipActive: 'myAccount.billing.membership.state.active',
      membershipCanceled: 'myAccount.billing.membership.state.canceled',
      membershipToStart: 'myAccount.billing.membership.state.toStart',
      membershipAmount: 'myAccount.billing.membership.field.amount',
      membershipCurrent: 'myAccount.billing.membership.current',
      membershipPrevious: 'myAccount.billing.membership.previous',
      membershipNext: 'myAccount.billing.membership.next',
      membershipRenewalDate: 'myAccount.billing.membership.field.renewalDate',
      membershipExpiredDate: 'myAccount.billing.membership.field.expiredDate',
      membershipStartDate: 'myAccount.billing.membership.field.startDate',
      membershipDesc: 'myAccount.billing.membership.desc',
      membershipPaymentDesc: 'myAccount.billing.membership.payment.desc',
      membershipCancelationDesc: 'myAccount.billing.membership.cancelation.desc',
      membershipManageLink: 'myAccount.billing.membership.manage.link',
      addCreditCard: 'myAccount.billing.addCreditCard.link',
      emptyDesc: 'myAccount.billing.payment.emptyDesc',
      failedPayment: 'myAccount.billing.payment.failed',
      expired: 'myAccount.billing.payment.expired',
      updatedBanner: 'myAccount.billing.payment.updated.success',
    },
  };

  const ActiveMembership = () => {
    const renewDate = format(membership?.expireAt);
    const nextProduct = useDowngradeInventory();

    return (
      <>
        {!membership?.hasMembership() && !membership?.expiredLast30Days() && (
          <p>
            <Translate resourceKey={i18n.payment.emptyDesc} values={checkoutUrlVariables} useHtml />
          </p>
        )}

        {membership?.expiredLast30Days() && (
          <p>
            <Translate
              resourceKey={paymentFailed ? i18n.payment.failedPayment : i18n.payment.expired}
              values={checkoutUrlVariables}
              useHtml
            />
          </p>
        )}

        {membership?.hasMembership() && (
          <div data-cy="current-product">
            <h6 className="text-heading_6">
              <Translate resourceKey={i18n.payment.membershipCurrent} />
            </h6>
            <p className="text-heading_5">
              <Translate resourceKey={i18n.payment.membershipTitle} values={{ name: membership.data?.name }} />
              <span className="label_status label_status--active">
                <Translate resourceKey={i18n.payment.membershipActive} />
              </span>
            </p>
            <div className="grid table current-products-table">
              <div className="xs-size_6-of-12">
                <div className="column-label">
                  <Translate resourceKey={i18n.payment.membershipAmount} />
                </div>
                <div className="column-content current-price">{`$ ${(membership.hasMembership()
                  ? membership.data?.price / 100
                  : 0
                ).toFixed(2)}`}</div>
              </div>
              <div className="xs-size_6-of-12">
                <div className="column-label">
                  <Translate
                    resourceKey={
                      membership.willBeDowngraded() || membership.willBeCanceled()
                        ? i18n.payment.membershipExpiredDate
                        : i18n.payment.membershipRenewalDate
                    }
                  />
                </div>
                <div className="column-content">{renewDate}</div>
              </div>
            </div>
          </div>
        )}

        {(membership?.expired() || membership?.wasCanceled()) && (
          <div data-cy="previous-product">
            <div className="inset-xs" />
            <h6 className="text-heading_6">
              <Translate resourceKey={i18n.payment.membershipPrevious} />
            </h6>
            <p className="text-heading_5">
              <Translate resourceKey={i18n.payment.membershipTitle} values={{ name: membership.data.name }} />
              <span className="label_status label_status--canceled">
                <Translate resourceKey={i18n.payment.membershipCanceled} />
              </span>
            </p>
          </div>
        )}

        {membership?.willBeDowngraded() && nextProduct && (
          <div data-cy="next-product">
            <div className="divider" />

            <h6 className="text-heading_6">
              <Translate resourceKey={i18n.payment.membershipNext} />
            </h6>
            <p className="text-heading_5">
              <Translate resourceKey={i18n.payment.membershipTitle} values={{ name: `HDC ${nextProduct.name}` }} />
              <span className="label_status label_status--pending">
                <Translate resourceKey={i18n.payment.membershipToStart} />
              </span>
            </p>

            <div className="grid table next-products-table">
              <div className="xs-size_6-of-12">
                <div className="column-label">
                  <Translate resourceKey={i18n.payment.membershipAmount} />
                </div>
                <div className="column-content">{`$ ${(nextProduct.priceCents.amount / 100).toFixed(2)}`}</div>
              </div>
              <div className="xs-size_6-of-12">
                <div className="column-label">
                  <Translate resourceKey={i18n.payment.membershipStartDate} />
                </div>
                <div className="column-content">{format(new Date(membership.data.subscription.renewalAt))}</div>
              </div>
            </div>
          </div>
        )}
        {membership?.willBeCanceled() ? (
          <>
            <Translate resourceKey={i18n.payment.membershipCancelationDesc} values={{ value: renewDate }} />
            {!membership.isVip() && (
              <>
                <div className="inset-s" />
                <AutoRenewButton willBeCanceled={membership?.willBeCanceled()} backUrl="/my-account/payments/" />
              </>
            )}
          </>
        ) : (
          membership?.hasMembership() && (
            <>
              <div className="inset-s" />
              <Translate resourceKey={i18n.payment.membershipDesc} values={{ date: renewDate }} />
              <div className="inset-s" />
              <Link to={'/my-account/change-plan/'} className="button button_secondary" data-cy="change-membership">
                <Translate resourceKey={i18n.payment.membershipManageLink} />
              </Link>
            </>
          )
        )}
      </>
    );
  };
  const loading = !isAuthenticated || membershipQuery.loading;
  if (loading) {
    return <LoadingContainer />;
  }

  return (
    <>
      {loading ? (
        <LoadingContainer />
      ) : (
        <>
          {banner?.display && <Banner banner={banner} setBanner={setBanner} />}
          <div className="container container_center page__my-account">
            <Breadcrumbs originTransKey={i18n.breadcrumb} originPath={'/my-account/'} />
            <div className="inset-m" />
            <h2 className="text-display_2">
              <Translate resourceKey={i18n.title} />
            </h2>
            <div className="inset-m" />
            <h3 className="text-display_3">
              <Translate resourceKey={i18n.payment.title} />
            </h3>
            <div className="inset-s" />
            <div className="balance-due-box">
              <p className="text_4">
                <Translate resourceKey={i18n.payment.balance} />
              </p>
              <span className="text-lead">$0.00</span>
            </div>
            <div className="inset-s" />
            <ActiveMembership />

            {!defaultPaymentMethodLoading && (
              <>
                <div className="inset-m" />
                {(card && (
                  <PaymentMethod
                    card={card}
                    provider={defaultPaymentMethod?.provider}
                    onEdit={() => navigate('/my-account/edit-card/', { state: { origin: '/my-account/payments/' } })}
                    error={paymentMethodError}
                  />
                )) || (
                  <Link
                    to={'/my-account/edit-card/'}
                    state={{ origin: '/my-account/payments/' }}
                    className="button button_secondary"
                    data-cy="new-payment-method"
                  >
                    <Translate resourceKey={i18n.payment.addCreditCard} />
                  </Link>
                )}
              </>
            )}
            {!membership?.hasMembership() && membership?.willBeCanceled() && (
              <Translate resourceKey={i18n.payment.cardDescription} />
            )}

            <div className="inset-m" />
            <TransactionsList orders={orders} orderMeta={orderMeta} orderFetchMore={orderFetchMore} />
          </div>
        </>
      )}
      <div className="inset-l" />

      <SimpleFooter />
    </>
  );
}

export default PaymentBill;
